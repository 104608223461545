import React from 'react';

export default function Footer() {
    return (
        <footer>
            <footer>
                <div className="container">
                    <div className="row">
                        <div
                            className="col-lg-2 col-md-12 d-flex justify-content-center justify-content-lg-start mb-lg-0 mb-4 footer-logo">
                            <div className="logo">
                                <img src="/skin/img/logo.svg" alt=""/>TonMobile<b>.</b>
                            </div>
                        </div>
                        <div
                            className="col-lg-10 col-md-12 d-flex justify-content-center justify-content-lg-end flex-wrap footer-nav">
                            <ul className="nav grey mb-2 mb-lg-0">
                                <li><a href="/en/privacy">Privacy Policy</a></li>
                                <li><a href="/en/complaints">Complaints Policy</a></li>
                                <li><a href="/en/agreement">Terms and conditions</a></li>
                            </ul>
                            <ul className="nav grey ml-8px">
                                <li><a href="mailto:support@tonmobile.com"
                                                                                 target="_blank">support@tonmobile.com</a></li>
                            </ul>
                        </div>
                    </div>
                    <hr/>
                    <div className="row copyright-row d-flex flex-lg-row flex-column-reverse">
                        <div className="col-lg-4 text-nowrap col-md-12 mt-lg-0 mt-md-4">
                            2024 © TonMobile. All rights reserved
                        </div>
                        <div className="col-lg-8 col-md-12">
                            <div className="row">
                                <div className="col-md-6 col-sm-12 lh-25px text-nowrap">
                                    <span className="op-50">About us</span><br/>
                                    Tonave Services LTD<br/>
                                    18 Souliou, 2018 Strovolos, Nicosia, Cyprus
                                    <hr className="d-block d-md-none"/>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="row">
                                        <div className="col-6 text-left text-md-right">
                                            <img src="/skin/img/icons/visa.svg" alt=""/>
                                            <img src="/skin/img/icons/mc.svg" alt=""/>
                                        </div>
                                        <div className="col-6 text-right">
                                            <div className="d-flex justify-content-end">
                                                <a href="#"
                                                   className="d-flex align-items-center flex-nowrap text-nowrap"><img
                                                    src="/skin/img/icons/to-top.svg" alt="" className="mr-12px"/>Back to
                                                    top</a>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="d-block d-md-none"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </footer>);
}
