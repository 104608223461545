let configValues;

if (process.env.REACT_APP_ENV === "PROD") {
    configValues = require('./config.PROD.js').default;
} else if (process.env.REACT_APP_ENV === "TEST") {
    configValues = require('./config.TEST.js').default;
}
else if (process.env.REACT_APP_ENV === "WEB") {
    configValues = require('./config.WEBSITE.js').default;
} else {
    configValues = require('./config.DEV.js').default;
}

export default configValues;

