import React from "react";

export default function Complaints() {
    return (
        <main>
            <div class="container page mb-5">
                <h1>Complaints Policy</h1>
                <p>
                    <strong>1. Definitions</strong>
                </p>
                <p>
                    1.1 In this Complaints Policy, the following expressions have the following
                    meanings:
                </p>
                <p>
                    <strong>“Company”</strong>: TONAVE SERVICES LIMITED
                </p>
                <p>
                    <strong>“Complaint”</strong> means a complaint about services sold/rendered
                    by TONAVE SERVICES LIMITED, including our customer service;
                </p>
                <p>
                    <strong>“Complaints Policy”</strong> means this document;
                </p>
                <p>
                    <strong>“Complaints Procedure”</strong> means the internal complaints
                    handling procedure of TONAVE SERVICES LIMITED which is followed when
                    handling a Complaint;
                </p>
                <p>
                    <strong>“Complaint Reference”</strong> means a unique code assigned to Your
                    Complaint;
                </p>
                <p>
                    <strong>2. Purpose of this Complaints Policy</strong>
                </p>
                <p>
                    2.1 TONAVE SERVICES LIMITED welcomes and encourages feedback of all kinds
                    from our customers. If You have a Complaint about our services or our
                    customer service, not only do we want to resolve it to Your satisfaction but
                    we also want to learn from it in order to improve our business and customer
                    experience in the future.
                </p>
                <p>
                    2.2 It is our policy to resolve Complaints quickly and fairly, where
                    possible without recourse to formal investigations or external bodies. In
                    particular, the aims of this Complaints Policy are:
                </p>
                <p>
                    2.2.1 To provide a clear and fair procedure for any customers who wish to
                    make a Complaint about the Company, our services, our customer service, or
                    our employees;
                </p>
                <p>
                    2.2.2 To ensure that all Complaints are handled equally and in a fair and
                    timely fashion;
                </p>
                <p>
                    2.2.3 To ensure that important information is gathered from Complaints and
                    used in the future to avoid such situations arising again.
                </p>
                <p>
                    2.3 PLEASE NOTE that all refund options are set out in Our Terms and
                    Conditions available on
                    <a href="https://tonmobile.com/en/agreement">
                        https://tonmobile.com/en/agreement
                    </a>
                    .
                </p>
                <p>
                    <strong>3. What this Complaints Policy Covers</strong>
                </p>
                <p>
                    3.1 This Complaints Policy applies to the provision of services by the
                    Company and to our customer service.
                </p>
                <p>
                    3.2 Complaints may relate to any of our activities and may include (but not
                    be limited to):
                </p>
                <p>
                    3.2.1 The quality of customer service You have received from the Company;
                </p>
                <p>
                    3.2.2 Delays, defects, poor workmanship or other problems associated with
                    the services provided by the Company;
                </p>
                <p>
                    3.2.3 Other.
                </p>
                <p>
                    3.3 The following are not considered to be Complaints and should therefore
                    be directed to the appropriate department:
                </p>
                <p>
                    3.3.1 General questions about our services;
                </p>
                <p>
                    3.3.2 Matters concerning contractual or other legal disputes;
                </p>
                <p>
                    3.3.3 Formal requests for the disclosure of information, for example, under
                    applicable legislation.
                </p>
                <p>
                    <strong>4. Making a Complaint</strong>
                </p>
                <p>
                    4.1 All Complaints, whether they concern our services or our customer
                    service, should be made by email, addressed to
                    <a href="mailto:support@tonmobile.com">
                        support@tonmobile.com
                    </a>
                    ;
                </p>
                <p>
                    4.2 When making a Complaint, You will be required to provide the following
                    information in as much detail as is reasonably possible:
                </p>
                <p>
                    4.2.1 Your name and Telegram username registered with the Company’s service
                    (We will contact You using Your preferred contact method as Your Complaint
                    is handled);
                </p>
                <p>
                    4.2.2 If You are making a Complaint on behalf of someone else, that person’s
                    name and contact details as well as Your own;
                </p>
                <p>
                    4.2.3 If You are making a Complaint about a particular transaction, full
                    details of such transaction available to you;
                </p>
                <p>
                    4.2.4 Further details of Your Complaint including, as appropriate, all
                    times, dates, events, and matters involved;
                </p>
                <p>
                    4.2.5 Details of any documents or other evidence You wish to rely on in
                    support of Your Complaint;
                </p>
                <p>
                    4.2.6 Details of what You would like the Company to do to resolve Your
                    Complaint and to put things right. (Please note that whilst we will make
                    every reasonable effort to accommodate such requests, we are not bound to
                    take any action beyond that which we may be contractually or otherwise
                    legally obliged to take.)
                </p>
                <p>
                    4.3 The Complaint shall be filed not later than 5 (five) calendar days
                    following the incident which is the underlying ground for the Complaint,
                    otherwise the Company reserves its right to reject the Complaint.
                </p>
                <p>
                    4.4 PLEASE NOTE that the Company reserves its right to reject the Complaint
                    without consideration in case it contains: (i) offensive language towards
                    the Company; (ii) obscene language; (iii) racist, ethnically offensive,
                    threatening, infringing or libelous wording.
                </p>
                <p>
                    <strong>5. How We Handle Your Complaint</strong>
                </p>
                <p>
                    5.1 If the information detailed in Section 4.2 is missing, insufficiently
                    detailed, or incomplete, or in case we need to verify your identity, We will
                    contact You within 10 calendar days of receipt of the Complaint to request
                    further information. PLEASE NOTE that if You fail to provide a reasonable
                    amount of information supporting the Complaint, the Company reserves its
                    right to either resolve the Complaint based on the information provided by
                    You or reject the Complaint.
                </p>
                <p>
                    5.2 If we require any further information or evidence from You, We will
                    contact You as quickly as is reasonably possible to ask for it. We ask that
                    You use reasonable efforts to supply any such information or evidence
                    quickly in order to avoid delaying the complaints handling process. If You
                    are for any reason unable to provide such information or evidence, we will
                    use all reasonable efforts to proceed without it; however, please be aware
                    that we will not ask for further information or evidence unless we consider
                    it important to the successful resolution of Your Complaint.
                </p>
                <p>
                    5.3 We aim to resolve Complaints within 30 calendar days; however, in some
                    cases, particularly if Your Complaint is of a complex nature, this may not
                    be possible. If this is not possible for any reason, You will be informed of
                    the delay, the likely length of the delay, and the reasons for it.
                </p>
                <p>
                    5.4 At the conclusion of the complaints procedure, regardless of the
                    outcome, we will provide You with details of our investigation, our
                    conclusions from that investigation, and any action taken as a result.
                </p>
                <p>
                    <strong>6. Confidentiality and Data Protection</strong>
                </p>
                <p>
                    6.1 All Complaints and information relating thereto are treated with the
                    utmost confidence. Such information will only be shared with those employees
                    of the Company who need to know in order to handle Your Complaint.
                </p>
                <p>
                    6.2 We may ask for Your permission to use details of Your Complaint (with
                    Your personal details removed) for internal training and quality improvement
                    purposes. If You have given such permission, You may revoke it at any time
                    by contacting Us using the details provided above in Section 4.1.
                </p>
                <p>
                    6.3 All personal information that we may collect (including, but not limited
                    to, Your name and address) will be collected, used, and held in accordance
                    with the Company’s Privacy Policy.
                </p>
                <p>
                    <strong>7. Policy Responsibility and Review</strong>
                </p>
                <p>
                    7.1 This Complaints Policy is regularly reviewed and updated as required.
                </p>
                <p>
                    7.2 This Complaints Policy was adopted on 12 June 2024.
                </p></div>
        </main>
    )
}
