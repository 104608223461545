import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Main from "./pages/main";
import Header from "./components/header";
import Footer from "./components/footer";
import Privacy from "./pages/privacy";
import Complaints from "./pages/complaints";
import Agreement from "./pages/agreement";
import Esim from "./pages/esim/esim";
import Faq from "./pages/faq";
import {
    localization, LocalizationContext
} from "./common/Localization";

export default function App() {
    return (
        <>
            <LocalizationContext.Provider value={localization}>
                <div className="AppContainer">
                    <Header/>
                    <BrowserRouter>
                        <Routes>
                            <Route path={"/"} element={<Main/>}/>
                            <Route path={"/esim/*"} element={<Esim/>}/>
                            <Route path={"/en"}>
                                <Route index element={<Navigate to='/'/>}/>
                                <Route path={"privacy"} element={<Privacy/>}/>
                                <Route path={"complaints"} element={<Complaints/>}/>
                                <Route path={"agreement"} element={<Agreement/>}/>
                                <Route path={"faq"} element={<Faq/>}/>
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </div>
                <Footer/>
            </LocalizationContext.Provider>
        </>
    );
}
