import React from 'react';

export default function Header() {
    return (
        <header>
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-sm-12 d-flex justify-content-center justify-content-md-start header-logo">
                        <a href="/" className="logo"><img src={"/skin/img/logo.svg"} alt=""/>TonMobile</a>
                    </div>
                    <div className="col-md-8 col-sm-12 d-flex justify-content-center justify-content-md-end mt-3 mt-md-0 header-nav">
                        <ul className="nav">
                            <li><a href="/#benefits">Benefits</a></li>
                            <li><a href="/#howitworks">How it works</a></li>
                            <li><a href="/en/faq">FAQ</a></li>
                            <li><a href="/esim/esim">Buy eSIM</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>);
}
